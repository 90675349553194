import React from "react"
import { Link, graphql } from "gatsby"
import formatDate from "date-fns/format"
import get from "lodash/get"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { getTranslationPath, getSongPath, getArtistPath } from "../utils/url"

const IndexPage = ({ data }) => {
  const translations = data.allStrapiTranslation.nodes

  return (
    <Layout>
      <SEO title="Αρχική" />
      <article className="text-gray-800 leading-normal font-serif">
        <p className="my-4">
          Το Υμνολόγιο.gr είναι μια διαδικτυακή βαση δεδομένων νεότερων
          χριστιανικών ύμνων που λειτουργεί υπό την αιγίδα του Σωματείου Ελλήνων
          Χριστιανών Καλλιτεχνών (ΣΕΧΚ).{" "}
          <Link to="/about" className="text-blue-700 hover:underline">
            Διαβάστε περισσότερα...
          </Link>
        </p>
        <p className="my-4">
          Μπορείτε να στείλετε τους δικούς σας ύμνους ή μετάφρασεις, καθώς και
          παρατηρήσεις, προτάσεις ή διορθώσεις, συμπληρώνοντας την{" "}
          <Link to="/contact" className="text-blue-700 hover:underline">
            φόρμα επικοινωνίας
          </Link>
          .
        </p>
      </article>
      <aside className="mt-10">
        <h2 className="font-bold font-sans text-gray-900 pt-6 text-xl md:text-2xl">
          Πρόσφατες καταχωρήσεις
        </h2>
        {translations.map(translation => {
          return (
            <div key={translation.id} className="my-4">
              <h4>
                <Link
                  to={getTranslationPath(translation)}
                  className="text-blue-700 hover:underline font-semibold"
                >
                  {translation.title}
                </Link>
              </h4>
              <div className="text-sm">
                <span className="mr-2 text-gray-700">Ημ/νία Καταχώρησης:</span>
                <span>
                  {formatDate(
                    new Date(translation.createdAt),
                    "MMMM dd, yyyy HH:mm"
                  )}
                </span>
              </div>
              {translation.song && (
                <div className="text-sm">
                  <span className="mr-2 text-gray-700">Τίτλος πρωτοτύπου:</span>
                  <Link
                    to={getSongPath(translation.song)}
                    className="text-blue-700 hover:underline"
                  >
                    {translation.song.title}
                    {translation.song.pubyear &&
                      ` (${translation.song.pubyear})`}
                  </Link>
                </div>
              )}
              <div className="text-sm">
                <span className="mr-2 text-gray-700">
                  {get(translation, ["song", "language"]) === "EL"
                    ? "Διασκευή"
                    : "Μετάφραση"}
                  :
                </span>
                <ul className="inline-block comma-separated">
                  {translation.translators.map(translator => {
                    return (
                      <li key={translator.id} className="inline-block">
                        <Link
                          to={getArtistPath(translator)}
                          className="text-blue-700 hover:underline"
                        >
                          {translator.name}
                        </Link>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          )
        })}
      </aside>
    </Layout>
  )
}

export const query = graphql`
  query {
    allStrapiTranslation(sort: { fields: createdAt, order: DESC }, limit: 5) {
      nodes {
        id: strapiId
        pubyear
        createdAt
        title
        translators {
          id
          name
        }
        song {
          title: Title
          id
          pubyear
        }
      }
    }
  }
`

export default IndexPage
